import { render, staticRenderFns } from "./InstallPWAModal.vue?vue&type=template&id=6076aed9&scoped=true&"
import script from "./InstallPWAModal.vue?vue&type=script&lang=ts&"
export * from "./InstallPWAModal.vue?vue&type=script&lang=ts&"
import style0 from "./InstallPWAModal.vue?vue&type=style&index=0&id=6076aed9&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6076aed9",
  null
  
)

export default component.exports