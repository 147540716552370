
























import { Component, Vue } from 'vue-property-decorator'
import { mapState } from 'vuex'
import Modal from '@/components/Modal.vue'
import Icon from '@/components/Icon.vue'
import { PlatformType } from '@/util/platform'

@Component({
  components: {
    Modal,
    Icon,
  },
  computed: {
    ...mapState('pwa', ['platform', 'showPWAModal']),
  },
})
export default class InstallPWAModal extends Vue {
  platform!: PlatformType | null

  get platformIcon() {
    return `pwa-${this.platform}`
  }

  onHide() {
    this.$store.commit('pwa/toggleModal', false)
    this.$store.dispatch('pwa/afterPrompt')
  }
}
